<template>
  <div>
    <v-container fluid class="fill-height">
      <v-row class="pa-0 ma-0">
        <v-col cols="10">
          <h1>
            Órdenes a
            <span class="font-weight-light">Validar</span>
          </h1>
        </v-col>

        <v-col cols="12" md="6">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="date"
                label="Filtrar por fecha"
                prepend-icon="fa-calendar-alt"
                readonly
                filled
                rounded
                hide-details
                :disabled="loading"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              range
              :title-date-format="title"
              scrollable
              :max="today"
            >
              <v-row no-gutters style="flex-wrap: nowrap">
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  outlined
                  color="primary"
                  @click="modal = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  color="primary"
                  @click="
                    $refs.dialog.save(date);
                    updateDateFilter();
                  "
                  >Filtrar</v-btn
                >
              </v-row>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="selectedCity"
            :items="cities"
            prepend-icon="fa-city"
            label="Filtrar por ciudad"
            item-text="name"
            item-value=".key"
            aria-autocomplete="false"
            autocomplete="off"
            hide-details
            filled
            rounded
            :allow-overflow="false"
            clearable
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="filteredLogs"
            :items-per-page="10"
            :loading="loading"
            sort-by="progressPlacedAt"
            :sort-desc="true"
            :single-expand="true"
            :expanded.sync="expanded"
            :search="search"
            item-key=".key"
            show-expand
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }"
          >
            <template v-slot:top>
              <v-dialog
                v-model="dialogAccept"
                max-width="410px"
                :persistent="formLoading"
              >
                <v-card
                  :loading="formLoading"
                  :disabled="formLoading"
                  class="py-1"
                >
                  <v-card-title>
                    <span class="headline text-center">
                      ¿Seguro que desea cambiar la orden
                      <b>{{ editedItem.shortId }}</b> a estado colocada?
                      <div
                        class="mt-2 d-block text-center caption"
                        style="width: 100%"
                      >
                        Ordenada de
                        <b>{{ editedItem.businessName }}</b>
                        <br />por
                        <b>{{ editedItem.userName }}</b>
                        {{ editedItem.progressPlacedAtRelative }}
                      </div>
                    </span>
                  </v-card-title>

                  <v-card-actions>
                    <v-row align="center" class="d-flex mb-4 mx-4">
                      <v-btn
                        rounded
                        class="flex-grow-1"
                        large
                        :elevation="0"
                        :minHeight="56"
                        :disabled="formLoading"
                        @click="closeAccept"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        color="primary"
                        rounded
                        class="flex-grow-1 ml-2"
                        large
                        :elevation="0"
                        :minHeight="56"
                        :disabled="formLoading"
                        @click="acceptOrder"
                      >
                        Aceptar
                      </v-btn>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:[`item.progressCurrentStatus`]="{ item }">
              <v-chip
                :color="getColor(item.progressCurrentStatus)"
                dark
                style="text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2)"
              >
                {{ item.progressCurrentStatus | statusFilter }}
              </v-chip>
            </template>

            <template v-slot:[`item.shortId`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.shortId)"
                  >
                    {{ item.shortId }}
                  </v-chip>
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.userName`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block; text-transform: capitalize"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.userName)"
                  >
                    {{ item.userName }}
                  </v-chip>
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.progressPlacedAtFiltered`]="{ item }">
              <span class>{{ item.progressPlacedAtRelative }}</span>
              <br />
              <span
                :class="
                  $vuetify.theme.dark
                    ? 'grey--text'
                    : 'grey--text text--darken-1'
                "
              >
                {{ item.progressPlacedAtFiltered }}
              </span>
            </template>

            <template v-slot:[`item.orderType`]="{ item }">
              <span class>{{ item.orderType | orderTypeName }}</span>
            </template>
            <template v-slot:[`item.businessFleetType`]="{ item }">
              <span class>{{ item.businessFleetType | fleetTypeName }}</span>
            </template>
            <template v-slot:[`item.orderTotal`]="{ item }">
              <span class>{{ item.orderTotalFiltered }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="acceptItem(item)"
                    class="mx-1"
                    fab
                    v-on="on"
                    :dark="$vuetify.theme.dark"
                    x-small
                    :elevation="0"
                    color="primary"
                  >
                    <v-icon dark>fa-check</v-icon>
                  </v-btn>
                </template>
                <span>Aceptar Orden</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-1"
                    fab
                    v-on="on"
                    @click="cancelOrder(item)"
                    :dark="$vuetify.theme.dark"
                    :disabled="
                      disabledCancelOrder.includes(item.progressCurrentStatus)
                    "
                    x-small
                    :elevation="0"
                    color="error"
                  >
                    <v-icon dark>fas fa-ban</v-icon>
                  </v-btn>
                </template>
                <span>Rechazar orden</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pa-4">
                <errand-details
                  v-if="item.orderType == 'errand' || item.orderType == 'cargo'"
                  :item="item"
                />
                <order-details v-else :item="item" />
              </td>
            </template>

            <template v-slot:loading>
              <div class="ma-5" style="position: relative">
                <div
                  style="
                    position: absolute;
                    z-index: 999;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <lottie
                    :options="defaultOptions"
                    :height="300"
                    :width="400"
                    v-on:animCreated="handleAnimation"
                  />
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>
                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                ></v-skeleton-loader>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog max-width="450" v-model="modalCancel" v-if="itemToCancel">
      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalCancel = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text style="font-size: 15px" class="mt-1">
          <p>¿Está seguro que desea rechazar la siguiente orden ?</p>

          <v-container fluid>
            <v-row>
              <v-col sm="6" cols="6" md="6">
                <span class="font-weight-bold">ID de la orden</span>
                <p>{{ itemToCancel.shortId }}</p>
              </v-col>
              <v-col sm="6" cols="6" md="6">
                <span class="font-weight-bold">Total de la orden</span>
                <p>{{ itemToCancel.orderTotal | currency }}</p>
              </v-col>
              <v-col sm="6" cols="6" md="6">
                <span class="font-weight-bold">Nombre del comercio</span>
                <p>{{ itemToCancel.businessName }}</p>
              </v-col>
              <v-col sm="6" cols="6" md="6">
                <span class="font-weight-bold">Nombre del cliente</span>
                <p>{{ itemToCancel.userName }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row align="center" class="d-flex mb-4 mx-4">
            <v-btn
              rounded
              class="flex-grow-1"
              large
              :elevation="0"
              :minHeight="56"
              :disabled="formLoading"
              @click="modalCancel = false"
              >Cancelar</v-btn
            >
            <v-btn
              color="red darken-4"
              rounded
              class="flex-grow-1 ml-2 white--text"
              large
              :elevation="0"
              :minHeight="56"
              :disabled="formLoading"
              @click="canceledConfirmed()"
              >Aceptar</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
  </div>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "../components/Lottie";
import * as animationData from "../assets/ochoColor.json";
// import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapState } from "vuex";

import orderDetails from "./c19/order-details";
import errandDetails from "./c19/errand-details";

export default {
  name: "order-validation",
  components: {
    lottie: Lottie,
    orderDetails,
    errandDetails,
  },
  data() {
    return {
      modal: null,
      date: [
        // inicio de mes
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
        // final del mes
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
      ],
      agent: null,
      agents: [],
      anim: null,
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      dialog: false,
      dialogAccept: false,
      snackbar: false,
      snackbarText: null,
      loading: true,
      formLoading: false,
      orders: [],
      cities: [],
      currentTime: Date.now(),
      selectedCity: "",
      expanded: [],
      itemsPerPageOptions: [4, 8, 12],
      itemsPerPage: 4,
      tagsToSearch: [],
      defaultItem: {},
      editedItem: {},
      itemToCancel: {},
      modalCancel: false,
      disabledCancelOrder: ["rejected"],
      searchableTags: [
        { name: "placed", color: "lime darken-1" },
        { name: "accepted", color: "green" },
        { name: "enRoute", color: "amber" },
        { name: "onDelivery", color: "orange" },
        { name: "delivered", color: "cyan" },
        { name: "completed", color: "blue" },
        { name: "canceled", color: "grey" },
        { name: "rejected", color: "red" },
      ],
      headers: [
        {
          text: "Estado",
          value: "progressCurrentStatus",
          align: "center",
          class: "nowrap",
        },
        {
          value: "timeFromPlacedStatus",
          class: "nowrap",
        },
        {
          text: "Estado del Pago",
          value: "orderPaymentStatus",
          align: "center",
          class: "nowrap",
        },
        {
          text: "Order ID",
          value: "shortId",
          align: "center",
          class: "nowrap",
        },
        {
          text: "Cliente",
          value: "userName",
          class: "nowrap",
        },
        {
          text: "Negocio",
          value: "businessName",
          width: "200px",
          class: "nowrap",
        },
        {
          text: "Tipo de delivery",
          value: "orderType",
          class: "nowrap",
        },
        {
          text: "Flota",
          value: "businessFleetType",
          class: "nowrap",
        },
        {
          text: "Monto Total",
          value: "orderTotal",
          align: "end",
          class: "nowrap",
        },
        {
          text: "Creada",
          value: "progressPlacedAtFiltered",
          filterable: false,
          width: "150",
          class: "nowrap",
        },
        { value: "actions", width: "200", align: "end", sortable: false },
      ],
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      var splitStr = value.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return " " + splitStr.join(" ");
    },
    orderTypeName: function (value) {
      let deliveryType = {
        regular: "Regular",
        pickUp: "Recogida",
        regularPickUp: "Recogida regular",
        none: "Ninguno",
        "": "Desconocido",
      };

      return deliveryType[value];
    },
    fleetTypeName: function (value) {
      let fleetType = {
        ocho: "Ocho",
        mixed: "Mixto",
        own: "Propia",
      };

      return fleetType[value];
    },
    currency(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },

    statusFilter(status) {
      return "Pendiente de Validar";
    },

    tsToDate(timestamp) {
      if (!timestamp) return "n/a";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY, HH:mm:ss [GMT]Z");
    },
    tsToTime(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("HH:mm:ss:SSS");
    },
  },
  computed: {
    ...mapState(["user"]),
    search() {
      return this.$store.state.search;
    },
    tomorrow() {
      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.toISOString().substr(0, 10);
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
    filteredLogs() {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      let filter = this.orders.map((x) => {
        // console.log(x);
        x.progressPlacedAtFiltered = x.progressPlacedAt
          ? moment(x.progressPlacedAt.toDate())
              .tz("America/Tegucigalpa")
              .format("DD/MM, hh:mm A")
          : "";
        x.progressPlacedAtRelative = x.progressPlacedAt
          ? moment(x.progressPlacedAt.toDate()).fromNow()
          : "";
        x.orderTotalFiltered = x.orderTotal
          ? formatter.format(x.orderTotal)
          : "";
        x.updatedAtRelative = x.updatedAt
          ? moment(x.updatedAt.toDate()).fromNow()
          : "";

        return x;
      });

      return filter;
    },
  },
  methods: {
    cancelOrder(item) {
      this.itemToCancel = item;
      this.modalCancel = true;
    },
    canceledConfirmed() {
      let data = {
        progressCurrentStatus: "rejected",
        progressRejectedAt: new Date(),
        progressLastModifiedAt: new Date(),
        cancelledBy: this.user[".key"],
      };

      db.collection("orders")
        .doc(this.itemToCancel[".key"])
        .update(data)
        .then((ref) => {
          this.snackbarText = "Orden rechazada exitosamente.";
          this.snackbar = true;
          this.modalCancel = false;
        })
        .catch((err) => {
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },

    acceptItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogAccept = true;
    },

    closeAccept() {
      this.dialogAccept = false;
      this.formLoading = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.agent = null;
      });
    },
    async acceptOrder() {
      this.loading = true;
      let reference = db.collection(
        this.editedItem.orderType == "errand" ||
          this.editedItem.orderType == "cargo"
          ? "ordersErrands"
          : "orders"
      );

      reference
        .doc(this.editedItem[".key"])
        .update({
          fleetId: null,
          progressCurrentStatus: "placed",
          progressPlacedAt: new Date(),
          // processTime: this.processTime ? this.processTime : null,
          placedBy: this.user[".key"],
          validatedBy: this.user[".key"],
        })
        .then(() => {
          this.loading = false;
          this.$emit("success");
          this.closeAccept();
        })
        .catch(() => {
          this.snackbar = true;
          this.snackbarText = `Error al aceptar Orden ${this.editedItem.shortId} 😢`;
          this.loading = false;
        });
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.formLoading = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    title() {
      return "Selecciona las fechas a filtrar";
    },
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    getColor(status) {
      const result = this.searchableTags.find(({ name }) => name === status);
      if (result) {
        return result.color;
      } else {
        return "gray";
      }
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    updateDateFilter() {
      if (this.date.length > 1) {
        if (moment(this.date[0], "YYYY-MM-DD") > new Date(this.date[1])) {
          this.date = [this.date[1], this.date[0]];
        }
      } else {
        this.date = [this.date[0], this.date[0]];
      }
    },
  },
  destroyed() {
    this.$store.commit("setSearchTerm", "");
    this.$store.commit("setSearch", "");
  },
  async mounted() {
    this.loading = true;
    this.$store.commit("setSearchTerm", "Órdenes");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    let query = db
      .collection(`orders`)
      .where("progressCurrentStatus", "==", "pendingValidation")
      .where(
        "progressPlacedAt",
        ">",
        moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
      )
      .where(
        "progressPlacedAt",
        "<",
        moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
      );

    if (this.user.type === "businessOwner") {
      query = query.where("businessId", "in", this.user.businesses);
    }

    await this.$binding("orders", query);

    await this.$binding("cities", db.collection("cities"));

    this.loading = false;
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    selectedCity(val) {
      this.loading = true;
      this.$store.commit("setSearch", "");
      this.orders = [];
      let query = db
        .collection(`orders`)
        .where("progressCurrentStatus", "==", "pendingValidation")
        .where(
          "progressPlacedAt",
          ">",
          moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
        )
        .where(
          "progressPlacedAt",
          "<",
          moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
        );
      if (this.user.type === "businessOwner") {
        query = query.where("businessId", "in", this.user.businesses);
      }
      this.$binding("orders", query).then((orders) => {
        if (this.selectedCity) {
          this.orders = this.orders.filter(
            (order) => order.cityId == this.selectedCity
          );
        }

        this.loading = false;
      });
    },

    date(val) {
      if (val.length > 1) {
        this.loading = true;
        this.$store.commit("setSearch", "");
        this.orders = [];
        let query = db
          .collection(`orders`)
          .where("progressCurrentStatus", "==", "pendingValidation")
          .where(
            "progressPlacedAt",
            ">",
            moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
          )
          .where(
            "progressPlacedAt",
            "<",
            moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
          );
        if (this.$store.state.user.type === "businessOwner") {
          query = query.where(
            "businessId",
            "in",
            this.$store.state.user.businesses
          );
        }
        this.$binding("orders", query)
          .then((orders) => {
            if (this.tagsToSearch.length > 0) {
              this.orders = orders.filter((order) => {
                return this.tagsToSearch.includes(order.progressCurrentStatus);
              });
            }
            if (this.selectedCity) {
              this.orders = this.orders.filter(
                (order) => order.cityId == this.selectedCity
              );
            }

            this.loading = false;
          })
          .catch((err) => {});
      }
    },
  },
};
</script>
